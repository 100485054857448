import { ColDef, GridOptions } from "ag-grid-community";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

export interface ApiCallGetAllForLookupTableParams {
  Filter: string;
  Sorting: string;
  SkipCount: number;
  MaxResultCount: number;
}

export interface ApiCallResponse<T> {
  result: T;
  targetUrl: string;
  success: boolean;
  error: {
    code: number;
    message: string;
    details: any;
    validationErrors: any;
  };
  unAuthorizedRequest: boolean;
  __abp: boolean;
}

export interface ApiCallGetAllResult<T> {
  totalCount: number;
  items: T[];
}

export interface ApiCallFileResult {
  fileName: string;
  fileType: string;
  fileToken: string;
}

export interface ApiCallGetAllForLookupTableItem {
  id: number;
  displayName: string;
}

export const ADMIN_DEFAULT_COL_DEF: ColDef = {
  minWidth: 100,
  resizable: true,
  sortable: true,
  filter: 'agTextColumnFilter',
};

/**
 * API call error handler.
 * @param httpErrorResponse Error returned from the HTTP call.
 * @param value Optional value to return as observable.
 * @returns An observable of the value passed as parameter or undefined.
 */
export function handleError(httpErrorResponse: HttpErrorResponse, value?: any): Observable<any> {
  const apiCallResponse = <ApiCallResponse<null>>httpErrorResponse.error;
  console.error(apiCallResponse.error.message);
  return of(value);
}

/**
 * Builds and sets AgGrid overlays and column definitions with translated string literals.
 * @param colDefs Array of AgGrid column definitions.
 * @param translateService TranslateService injection of NgxTranslate.
 * @param gridOptions AgGrid GridOptions to use.
 */
export function buildTranslatedAgGridOverlaysAndColDefs(
  colDefs: ColDef[], translateService: TranslateService, gridOptions: GridOptions
) {
  const translateKeys: string[] = ['loading', 'noElementsToShow'];
  for (const colDef of colDefs) translateKeys.push(colDef.field);
  translateService.get(translateKeys).subscribe(res => {
    gridOptions.overlayLoadingTemplate = `<div class="ag-grid-overlay-custom">` + res[translateKeys[0]] + `</div>`;
    gridOptions.overlayNoRowsTemplate = `<div class="ag-grid-overlay-custom">` + res[translateKeys[1]] + `</div>`;
    for (const colDef of colDefs) colDef.headerName = res[colDef.field] || colDef.field;
    gridOptions.columnDefs = colDefs;
  });
}
