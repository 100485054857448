import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

export class FormOptions {
    value: string;
    label: string;
}

export class ApplicationModel {
    id?:                         number;
    name?:                       string;
    description?:                string;
    active?:                     boolean;
    organizationOwnerId?:        number;
    organizationOwnerCode?:      string;
    iconBase64?:                 string;
    imageBase64?:                string;
    heritable?:                  boolean;
    moduleId?:                   number;
    moduleName?:                 string;
    applicationCategoryId?:      number;
    applicationCategoryName?:    string;
    applicationGroupsIds?:       number[];
    organizationIds?:            number[];
    applicationModules?:         ApplicationModuleModel[];
    translations?:               ApplicationTranslationModel[];
}

export interface ApplicationModuleModel {
    id:             number;
    moduleId:       number;
    moduleFatherId: number;
    exists:         boolean;
}

export interface ModuleModel {
    id:             number;
    name:           string;
    icon:           string;
    route:          string;
    description:    string;
    category:       string;
}

export class ApplicationGroup {
    name:                   string;
    description:            string;
    active:                 boolean;
    organizationOwnerId:    number;
    id:                     number;
}

export interface ApplicationCategoryModel {
    id:                    number;
    name:                  string;
    description:           string;
    iconBase64:            string;
    imageBase64:           string;
    organizationOwnerId:   number;
    organizationOwnerCode: string;
}

export interface ApplicationTranslationModel {
    name?:          string;
    description?:   string;
    language?:      string;
    icon?:          string;
}

export interface ApiCallResponse<T> {
    result: T;
    targetUrl: string;
    success: boolean;
    error: {
      code: number;
      message: string;
      details: any;
      validationErrors: any;
    };
    unAuthorizedRequest: boolean;
    __abp: boolean;
}

/**
 * API call error handler.
 * @param httpErrorResponse Error returned from the HTTP call.
 * @param value Optional value to return as observable.
 * @returns An observable of the value passed as parameter or undefined.
 */
export function handleError(httpErrorResponse: HttpErrorResponse, value?: any): Observable<any> {
    const apiCallResponse = <ApiCallResponse<null>>httpErrorResponse.error;
    console.error(apiCallResponse.error.message);
    return of(value);
}
