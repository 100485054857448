import { MasterModel } from "@app/gmao/models/master-model";
import { Plant } from "./plant";

export class Association {
    id: string = null;
    plant: Plant = new Plant();
    installation: MasterModel = new MasterModel();
    elementType: string = null;
    element: MasterModel = new MasterModel();

    constructor(content?: any) {
        if (content) {
            this.id = content.id || null;
            this.plant = new Plant(content.plant);
            this.installation = new MasterModel(content.installation);
            this.elementType = content.element ? content.element.type : null;
            this.element = new MasterModel(content.element);
        }
    }

    reset(resetPlant: boolean) {
        if (resetPlant) this.plant = new Plant();
        this.installation = new MasterModel();
        this.elementType = null;
        this.element = new MasterModel();
    }
}