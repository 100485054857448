import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConsts } from '@shared/AppConsts';
import { ProductWithFeatures } from './product-detail/product.model';
import { map } from 'rxjs/operators';
import { Product } from './models/product';
import { ProductFeature } from './models/product-feature';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  api_url: string = AppConsts.remoteServiceBaseUrl;

  constructor(private httpClient: HttpClient) { }

  getAllProducts() {
    return this.httpClient.get(`${this.api_url}/api/services/app/Products/GetAllWithRelationshipData`);
  }

  newProduct() {
    return this.httpClient.post(`${this.api_url}/api/services/app/Products/New`, {});
  }

  editProduct(productId: string, product: Product, features: ProductFeature[], translation:any[]) {
    let newProduct = {
      description: product.description,
      name: product.name,
      measurementUnitId: product.measurementUnit,
      organizationId: product.organization,
      productTypeId: product.productType,
      stockControlTypeId: product.stockControlType,
      features: features,
      translations: translation,
      id: productId
    };
    return this.httpClient.post(`${this.api_url}/api/services/app/Products/Edit`, newProduct);
  }

  duplicateProduct(pId: string) {
    const body = {
      Id: pId
    }
    return this.httpClient.post(`${this.api_url}/api/services/app/Products/Duplicate`, body);
  }

  deleteProduct(listOfId: string[]) {
    let params = new HttpParams();
    listOfId.forEach(x => {
      params = params.append('Id', x.toString());
    })
    return this.httpClient.delete(`${this.api_url}/api/services/app/Products/DeleteMultiple`, { params: params })
  }

  getProductDetail(pId: string) {
    let params = new HttpParams()
      .set('Id', pId);
    return this.httpClient.get(`${this.api_url}/api/services/app/Products/GetWithFeatures`, { params: params })
      .pipe(map((res: any) => <ProductWithFeatures>res.result));
  }

  resetProductFeatures(productId: string, newProductTypeId: string) {
    let body = {
      id: productId,
      productTypeId: newProductTypeId
    };
    return this.httpClient.post(`${this.api_url}/api/services/app/Products/ResetProductFeatures`, body)
  }
}
